import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import { HiMail } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import axios from "axios";
import { Link } from "react-router-dom";
import contacto from "../assets/contact.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import { withAlert } from "react-alert";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdPhone } from "react-icons/md";

const Contact = ({ alert }) => {
  const [cred, setCred] = useState({ email: "", phone: "", message: "" });
  const [robot, setRobot] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  function onChange(value) {
    setRobot(value);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (
      cred.email.length > 0 &&
      cred.phone.length > 0 &&
      cred.message.length > 0 &&
      robot
    ) {
      setLoading(true);
      if (document.getElementById("flexCheckDefault").checked) {
        await axios.post(process.env.REACT_APP_BASE_URL + "/api/auth/contact", {
          ...cred,
        });
        alert.show(<span className="small">¡MENSAJE ENVIADO!</span>, {
          type: "success",
        });
        document.getElementById("flexCheckDefault").checked = false;
        setCred({ phone: "", email: "", message: "" });
        setLoading(false);
      } else {
        alert.show(
          <span className="small">Acepta nuestros Términos y Condiciones</span>,
          {
            type: "error",
          }
        );
        setLoading(false);
      }
    } else {
      alert.show(<span className="small">Completar todos los datos!</span>, {
        type: "error",
      });
    }
  };

  const HandleChange = (e) => {
    setCred((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  return (
    <div className="contact bg-white column-resp">
      <PageBanner title={"Contacto"} img={contacto} />
      <div className="canvas col_resp pb-4">
        <h1 className="fw-bold mt-5 p-3 text-center">Atención al Cliente</h1>
        <form
          className="d-flex justify-content-between mt-4 col_resp"
          onSubmit={HandleSubmit}
        >
          <div className="register_1">
            <div className="d-flex w-100">
              <div className="d-flex align-items-center justify-content-center icon me-3">
                <HiMail size={40} color="white" />
              </div>
              <div>
                <h3 className="fw-bold">Email</h3>
                <p className="font2 mt-2">info.es@postalven.com</p>
                {/* <p className="font2 mt-2">info.ve@postalven.com</p> */}
                {/* <p className="font2 mt-2">pagos@postalven.com</p> */}
              </div>
            </div>
            <div className="d-flex w-100 mt-4">
              <div
                className="d-flex align-items-center justify-content-center
           icon me-3"
              >
                <FiPhoneCall size={37} color="white" />
              </div>
              <div>
                <h3 className="fw-bold">Call Center</h3>
                <div className="mt-2 d-flex align-items-center">
                  <MdPhone size={20} color="#fc364e" />
                  <p className="ms-3 font2">+34 91 993 12 13</p>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <MdPhone size={20} color="#fc364e" />
                  <p className="ms-3 font2">+58 424 358 2459</p>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <AiOutlineClockCircle size={20} color="#fc364e" />
                  <p className="ms-3 font2">L a V de 10 a 18 Hrs.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="register_2">
            <label>Nombre</label>
            <input
              type={"text"}
              className="rounded-0 form-control mt-2"
              onChange={(e) => HandleChange(e)}
              id="phone"
              value={cred.phone}
            />
            <label className="mt-1">Email</label>
            <input
              type={"email"}
              className="rounded-0 form-control mt-1"
              onChange={(e) => HandleChange(e)}
              id="email"
              value={cred.email}
            />
            <label className="mt-2">Mensaje</label>
            <textarea
              className="form-control mt-1 rounded-0"
              placeholder="Escribe tu mensaje"
              onChange={(e) => HandleChange(e)}
              id="message"
              value={cred.message}
            />

            <div className="d-flex align-items-start form-check mt-3">
              <input
                className="form-check-input mb-0"
                style={{ width: "44px", height: "23px", border: "2px solid black" }}
                type="checkbox"
                id="flexCheckDefault"
              />
              <label
                className="form-check-label text-muted ms-2"
                htmlFor="flexCheckDefault"
              >
                Haciendo click aquí, aceptas nuestros{" "}
                <Link to="/terms">Términos y Condiciones</Link> y
                <Link to={"/protection"}>
                  {" "}
                  Política general en materia de Protección de Datos
                </Link>{" "}
                de Postalven y nos autorizas a contactarte por E-mail, SMS y
                Whatsapp.
              </label>
            </div>

            <ReCAPTCHA
              sitekey="6LckYzAmAAAAANHlQmp4_aRMZKfTaTArrNogNvuK"
              onChange={onChange}
              className="mt-3"
              id="recaptcha"
              onExpired={(e) => e.reset()}
            />

            <p className="text-danger">{!robot && "Verify Recaptcha!"}</p>

            <button className="continue w-auto px-4">
              {loading ? "Enviando" : "Enviar"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withAlert()(Contact);
