import React, { useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Forget = () => {
  const [loading, setLoading] = useState(false)
  const [credentials, setCredentials] = useState({ email: "" });

  const onEmailSubmit = async () => {
    if (credentials.email.length > 0) {
      try {
        setLoading(true)
        await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/auth/send-reset-link?email=${credentials.email.toLowerCase()}`
        );
        toast.success("Enlace de restablecimiento de contraseña enviado!", {
          position: "top-center",
          theme: "dark",
        });
        setLoading(false)
        setCredentials({ email: "" })
      } catch (err) {
        setLoading(false)
        console.error(err?.response?.data);
        toast.error(<span className="small">{err?.response?.data}</span>, {
          type: "error",
          position: "top-center",
          theme: "dark",
        });
      }
    }
  };


  return (
    <div>
      <PageBanner title={"Recuperar Contraseña"} />
      <ToastContainer />
      <div className="mt-10 w-50 forgot_form py-3 mx-auto bg-white px-5 rounded shadow">
        <div>
          <label className="fw-bold">Email:</label>
          <input
            type={"email"}
            placeholder="Enviaremos un enlace para restablecer tu contraseña!"
            className="form-control mt-1 rounded-0"
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
            value={credentials.email}
          />
          <button
            className="continue w-auto px-3 mx-auto"
            onClick={onEmailSubmit}
            disabled={loading}
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Forget;
