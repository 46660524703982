import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Forget = () => {
    const [credentials, setCredentials] = useState({})
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();


    const ChangePassword = async () => {
        const token = searchParams.get("token");

        if (!token) {
            toast.error("Token de restablecimiento inválido o faltante!", {
                position: "top-center",
                theme: "dark",
            });
            return;
        }

        if (credentials.password === credentials.confirmpassword) {
            try {
                await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/auth/resetpassword`,
                    {
                        token,
                        password: credentials.password,
                    }
                );
                toast.success("Contraseña restablecida con éxito!", {
                    position: "top-center",
                    theme: "dark",
                });
                navigate("/login");
            } catch (error) {
                console.error(error?.response?.data);
                toast.error("Error al restablecer la contraseña!", {
                    position: "top-center",
                    theme: "dark",
                });
            }
        } else {
            toast.error("¡Las contraseñas no coinciden!", {
                position: "top-center",
                theme: "dark",
            });
        }
    };

    return (
        <div>
      <ToastContainer />
            <PageBanner title={"Recuperar Contraseña"} />
            <div className="col-md-8 col-11 mx-auto mt-5 bg-white p-5 rounded">
                <label className="fw-bold">Nueva Contraseña</label>
                <input
                    type={"password"}
                    className="form-control mt-1 rounded-0"
                    onChange={(e) =>
                        setCredentials({ ...credentials, password: e.target.value })
                    }
                />
                <label className="fw-bold mt-2">Confirma Contraseña</label>
                <input
                    type={"password"}
                    placeholder="Confirma Contraseña"
                    className="form-control mt-1 rounded-0"
                    onChange={(e) =>
                        setCredentials({
                            ...credentials,
                            confirmpassword: e.target.value,
                        })
                    }
                />
                <button
                    className="continue w-auto px-3 mx-auto"
                    onClick={ChangePassword}
                >
                    Confirmar
                </button>
            </div>
        </div>
    );
};

export default Forget;
